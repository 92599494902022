<template>
  <div class="findApplyMsg">
    <!-- 顶部 -->
    <Header :titleName="data.titleName" :return="true"  :borderShow="true"/>
    <div class="content">
      <div v-if="data.detail.goodType == 1">
        <div class="f42 fw6" style="margin-bottom: .2rem;">{{data.detail.sendProductNumber?'请注意查收':'请等待发货'}} </div>
        <div class="f24" style="margin-bottom: .5rem;color: #888;">{{data.detail.sendProductNumber?'申领物品已发货，请耐心等待':'申领物资稍后发出，请耐心等待！'}} </div>
        <div class="itemName">
          <div class="flex">
            <img
              :src="data.detail.imgUrl"
              alt="">
            <div class="lir" style="width: 6rem;">
              <div style="font-size: .3867rem;margin-bottom: .1rem;">{{data.detail.applyForProjectName}}</div>
              <div class="time">申领日期：{{data.detail.addTime}}</div>
            </div>
          </div>
        </div>
        <div class="hxm">
          <div class="f29 fw6" style="margin-bottom: .8rem;">物流信息</div>
          <div style="text-align: center;">
            <img  class="expressage_img" src="@/assets/images/find/expressage_img.png" alt="">
            <div style="color: #888;">{{data.detail.sendProductNumber?data.detail.expressCompany:'暂无物流信息'}}</div>
          </div>

          <div class="copy" @click="copyToClipboard()" v-if="data.detail.sendProductNumber">
            <div style="color: #888;margin-right: .2rem;">快递单号</div>
            <div style="font-weight: 600;margin-right: .2rem;">1001253654789525</div>
            <div style="color: #095ECC;">复制</div>
          </div>
        </div>
      </div>
      <div v-if="data.detail.goodType == 2">
        <div class="f42 fw6" style="margin-bottom: .2rem;">{{data.detail.productCardNo?'待核销':'待发货'}}</div>
        <div class="f24" style="margin-bottom: .5rem;color: #888;" v-if="data.detail.productCardNo">核销码有效期至{{data.detail.expiryDate}}</div>
        <div class="f24" style="margin-bottom: .5rem;color: #888;" v-else>申领物资末发货，请耐心等待!</div>
        <div class="itemName">
          <div class="flex">
            <img :src="data.detail.imgUrl" alt="">
            <div class="lir" style="width: 6rem;">
              <div style="font-size: .3867rem;margin-bottom: .1rem;">{{data.detail.applyForProjectName}}</div>
              <div class="time">申领日期：{{data.detail.addTime}}</div>
            </div>
          </div>
        </div>
        <div class="hxm">
          <div class="f29 fw6" style="margin-bottom: .4rem;">核销码</div>
          <div style="text-align: center;">
            <img class="ewm" :src="data.qrCodeData" alt="" :style="data.detail.productCardNo?'':'filter:blur(4px);'">
          </div>
          
          <div class="copy" @click="copyToClipboard()" v-if="data.detail.productCardNo">
            <div style="color: #888;margin-right: .2rem;">核销码</div>
            <div style="font-weight: 600;margin-right: .2rem;">{{ data.detail.productCardNo }}</div>
            <div style="color: #095ECC;">复制</div>
          </div>
          <div v-else style="color: #888;text-align: center;">暂无核销码</div>
        </div>
      </div>

    </div>


  </div>
</template>

<script setup>
import { reactive, toRefs, onMounted } from "vue";
import qrcode from 'qrcode'
import { useRoute, useRouter } from 'vue-router'
import Header from "../../components/Header.vue";
import Api from "../../utils/api";
import { Toast, Popup } from 'vant';
import { openAppFunction } from '../../common/js/share'
const route = useRoute()
const router = useRouter()
let data = reactive({
  detail:{},
  list: [],
  option1: [
    { text: '身份证', value: 0 },
    { text: '手机号', value: 1 },
    { text: '姓名', value: 2 }
  ],
  titleName:'申领信息',
  id: 0,
  value1: 0,
  postData: {
    realName: '',
  },
  qrCodeData: '',
  AFPdata: {
    StartStatus: 0,
    typeId: 0,
    GoodType: 0,
    Keyword: '',
    PageSize: 10,
    PageIndex: 1,
  },
});

onMounted(() => {
  data.id = route.query.id

 
  getDeatil()

  shareFn()

});
// 分享详情
const shareFn = () => {
  const jsonString = {
    shareUrl:window.location.href,//分享链接
    shareCorver: window.location.origin + '/logo.png',
    shareTitle: '中国职工发展基金会',
    shareDesc: data.titleName,//分享描述
  }
  console.log(jsonString)
  openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}

const getDeatil = () => {

  Api.applyUserGet(data.id).then(res => {
    if (res.status == 200) {
      data.detail = res.data
      if(data.detail.goodType == 2){
        getqrcode()
      }
      
    }
  })
}
const getqrcode = () => {
  let qrNumber = data.detail.productCardNo?data.detail.productCardNo:'11111'
  qrcode.toDataURL(qrNumber, (err, url) => {
    if (err) {
      console.error(err);
    } else {
      data.qrCodeData = url;
    }
  })
}
const copyToClipboard = () => {
  // 创建一个textarea元素
  const textarea = document.createElement('textarea');
  // 设置textarea的值为要复制的文本
  textarea.value = data.detail.productCardNo;
  // 将textarea添加到文档中
  document.body.appendChild(textarea);
  // 选中textarea中的文本
  textarea.select();
  try {
    // 尝试复制选中的文本
    const successful = document.execCommand('copy');
    const msg = successful ? '复制成功' : '复制失败';
    Toast('核销码' + msg)
    console.log(msg);
  } catch (err) {
    console.log('不能使用这种方法复制', err);
  }
  // 将textarea从文档中移除
  document.body.removeChild(textarea);
}





</script>

<style lang='less' scoped>
/deep/ .van-tabs__wrap {
  border-radius: 10px;
}

::v-deep input.van-field__control::-webkit-input-placeholder {
  color: #ACACAC;
  font-size: .3867rem;
}

.findApplyMsg {
  display: flex;
  flex-flow: column;
  height: 100%;
  font-size: 12px;


  .content {
    flex: 1;
    overflow-y: auto;
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 40%, #F5F5F5 100%);
    padding: .3067rem;

    .hxm {
      width: 9.3867rem;
      height: 7rem;
      background: #FFFFFF;
      box-shadow: .08rem .08rem .08rem .0133rem rgba(0, 0, 0, 0.05);
      border-radius: .2533rem;
      padding: .3067rem;

      .ewm {
        width: 3.28rem;
      }
      .expressage_img{
        width: 2.5067rem;
        margin-bottom: .1rem;
      }
      .copy {
        width: 7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F5F5F5;
        padding: 0 .2933rem;
        height: .6133rem;
        font-size: .3067rem;
        border-radius: .1067rem;
        margin: .4rem auto;
      }
    }

    .itemName {
      width: 9.3867rem;
      background: #FFFFFF;
      box-shadow: .08rem .08rem .08rem .0133rem rgba(0, 0, 0, 0.05);
      border-radius: .2533rem;
      padding: .3067rem;
      font-size: .3867rem;
      padding-top: .2667rem;
      position: relative;
      margin-bottom: .3rem;

      .time {
        color: #ACACAC;
        font-size: .3067rem;
      }

      img {
        width: 2.8267rem;
        height: 2.1733rem;
        border-radius: .1067rem;
        margin-right: .3333rem;
      }

    }

    .f42 {
      font-size: .56rem;
    }

    .f24 {
      font-size: .32rem;
    }

    .f29 {
      font-size: .3867rem;
    }


  }
}
</style>